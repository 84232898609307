import type { SerializedStyles, Theme } from "@emotion/react";
import { Children } from "react";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "@aviary";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { l } from "@shared/locales/i18n";

import FsEeDualLogoIcon from "../../assets/images/fs-ee-dual-logo-icon.png";

import * as styles from "./MultiplePlatformWarning.styles";

const { isWrapped, justifyEnd, container, messageContainer, dualIcon } = styles;
const DualIcon = () => <img src={FsEeDualLogoIcon} alt="dual-icon" css={dualIcon} />;

interface Props {
  isMultiplatform?: boolean;
  children?: ReactNode;
  isJustifyEnd?: boolean;
  childrenStyles?:
    | SerializedStyles
    | (SerializedStyles | ((theme: Theme) => SerializedStyles))[]
    | ((theme: Theme) => SerializedStyles);
}

const MultiplePlatformWarning = ({
  isMultiplatform,
  children,
  isJustifyEnd,
  childrenStyles,
}: Props) => {
  const { t } = useTranslation();
  const { tablet } = useBreakpoints();
  const isMobile = tablet.lessThan;

  const renderWarning = () => (
    <div css={messageContainer}>
      <DualIcon />
      <Typography type="footnote">{t(l.sharedAccountSettings.MultiplePlatformWarning)}</Typography>
    </div>
  );

  const hasChildren = Children.count(children) > 0;

  if (!isMultiplatform && !hasChildren) return null;

  return (
    <div css={[container, isMobile && isWrapped, isJustifyEnd && justifyEnd]}>
      {isMultiplatform && renderWarning()}
      {hasChildren && <div css={childrenStyles}>{children}</div>}
    </div>
  );
};
export { MultiplePlatformWarning };
